import React, { useEffect, useState } from "react";
import MarkdownContent from "./MarkdownContent";
import { usePage } from "@/lib/api";
import { Skeleton } from "@mui/material";

export const DefaultPage = ({ page, pageToLoad, ...props }) => {
  const [ content, setContent ] = useState(page?.content);
  const { page: loadedPage, isLoading } = usePage(pageToLoad);

  useEffect(() => {
    if (loadedPage) {
      setContent(loadedPage.content);
    }
  }, [loadedPage]);

  if (pageToLoad && isLoading) {
    return <Skeleton />
  }

  return (<main><MarkdownContent input={content} /></main>);
};
