import { basierCirleBold } from "@/styles/fonts";
import theme from "@/styles/theme";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, useMediaQuery } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";
import * as React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const DialogStyled = ({
  children,
  open,
  onClose,
  cancelButton = false,
  showCloseButton = true,
  maxWidth,
  offCanvasMenu = false,
  showFullscreen = false,
  disableCloseOnBackgroundClick = false,
}) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = (event, reason) => {
    if (
      onClose &&
      disableCloseOnBackgroundClick &&
      reason &&
      (reason === "backdropClick" || reason === "escapeKeyDown")
    ) {
      return;
    }
    if (onClose && typeof onClose === "function") {
      onClose();
    }
  };

  const dialog = {
    height:
      showFullscreen && isSmallScreen
        ? "100%"
        : offCanvasMenu
          ? "100vh"
          : "auto",
    width: offCanvasMenu ? "90vw" : "auto",
    [theme.breakpoints.up("sm")]: {
      width: offCanvasMenu ? "70vw" : "auto",
    },
    [theme.breakpoints.up("md")]: {
      width: offCanvasMenu ? "33vw" : "auto",
    },
    bgcolor: "#fbfbfb",
    boxShadow: 24,
    padding: "1.5rem",
    "& .MuiTypography-root": {
      "& span": {
        fontWeight: basierCirleBold.style.fontWeight,
        fontFamily: basierCirleBold.style.fontFamily,
      },
    },
    "& .modal": {
      "&--buttons": {
        mt: 4,
      },
    },
    "& .close-dialog-button": {
      position: "absolute",
      top: 0,
      right: 0,
      transform: "translate(-.25rem, .25rem)",
      color: theme.palette.common.black,
    },
  };

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        TransitionComponent={offCanvasMenu ? Transition : Fade}
        PaperProps={{
          sx: {
            position: offCanvasMenu ? "fixed" : "relative",
            top: offCanvasMenu ? 0 : "",
            right: offCanvasMenu ? 0 : "",
            bottom: offCanvasMenu ? 0 : "",
            margin: showFullscreen || offCanvasMenu ? 0 : "2rem",
            maxHeight:
              (showFullscreen && isSmallScreen) || offCanvasMenu
                ? "100%"
                : "calc(100% - 4rem)",
            maxWidth: offCanvasMenu ? "unset" : "800px",
            height: showFullscreen && isSmallScreen ? "100%" : "auto",
          },
        }}
      >
        <Box sx={dialog}>
          {showCloseButton && (
            <IconButton
              size="large"
              aria-label="Schließen"
              onClick={handleClose}
              className="close-dialog-button"
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
          {children}
          {cancelButton && (
            <Button
              onClick={handleClose}
              fullWidth
              variant="outlined"
              color="primary"
              sx={{ marginTop: "0.5rem" }}
            >
              Abbrechen
            </Button>
          )}
        </Box>
      </Dialog>
    </>
  );
};
