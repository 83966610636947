import styles from "@/styles/components/CookieConsentProvider.module.scss";
import Typography from "@mui/material/Typography";
import TabsStyled from "@/components/tabs/TabsStyled";
import TabPanel from "@/components/tabs/TabPanel";
import { Box } from "@mui/system";
import { AccordionStyled } from "@/components/accordion/AccordionStyled";
import Grid from "@mui/material/Grid";
import { Toggle } from "@/components/Toggle";
import { DefaultPage } from "@/components/DefaultPage";
import { Button } from "@mui/material";
import { DialogStyled } from "@/components/DialogStyled";
import { useState } from "react";

const HEADERS = ["Einstellungen", "Impressum", "Datenschutz"];

export default function CookieConsentDialog({
  selectedOptions,
  modalIsOpen,
  onCookieToggled,
  onSave,
  onDecline,
  onAccept,
}) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  return (
    <DialogStyled
      open={modalIsOpen}
      showCloseButton={false}
      maxWidth="md"
      className={styles}
    >
      <Typography
        variant="h4"
        component="p"
        color="common.black"
        sx={{ mb: 1 }}
      >
        Unsere Cookies
      </Typography>
      <Typography sx={{ my: 2 }}>
        Unsere Webseite verwendet Cookies. Diese haben zwei Funktionen: Zum
        einen sind sie erforderlich für die grundlegende Funktionalität unserer
        Website. Zum anderen können wir mit Hilfe der Cookies unsere Inhalte für
        dich immer weiter verbessern. Das Einverständnis in die Verwendung der
        Cookies kannst du jederzeit widerrufen.
      </Typography>
      <TabsStyled
        headers={HEADERS}
        label="Einstellungen"
        onTabChanged={setSelectedTabIndex}
      >
        <TabPanel index={0} value={selectedTabIndex}>
          <Box>
            {selectedOptions &&
              selectedOptions.map((cookie, idx) => (
                <AccordionStyled key={idx} question={cookie.name} id={idx + 1}>
                  {cookie.options ? (
                    <Box className={styles.toggleWrapper}>
                      {cookie.options.map((option, idy) => (
                        <Grid
                          key={idy}
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          className={styles.toggleRow}
                        >
                          <Grid item xs="auto">
                            {option.name}
                          </Grid>
                          <Grid item xs="auto">
                            <Toggle
                              disabled={cookie.readonly}
                              onChange={onCookieToggled}
                              value={option.status}
                              name={option.name}
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </Box>
                  ) : (
                    <Typography variant="body1">
                      Technisch notwendige Cookies ermöglichen grundlegende
                      Funktionen und sind für die einwandfreie Funktion der
                      Website erforderlich.
                    </Typography>
                  )}
                </AccordionStyled>
              ))}
          </Box>
        </TabPanel>
        <TabPanel index={1} value={selectedTabIndex}>
          <Box className={styles.legalWrapper}>
            <DefaultPage pageToLoad="/imprint" />
          </Box>
        </TabPanel>
        <TabPanel index={2} value={selectedTabIndex}>
          <Box className={styles.legalWrapper}>
            <DefaultPage pageToLoad="/privacy" />
          </Box>
        </TabPanel>
      </TabsStyled>
      <Grid container spacing={2} className={styles.buttonWrapper}>
        <Grid item xs={12} sm>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            className={styles.button}
            onClick={onSave}
          >
            Einwilligungen speichern
          </Button>
        </Grid>
        <Grid item xs={12} sm>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            className={styles.button}
            onClick={onDecline}
          >
            Nur technisch notwendige
          </Button>
        </Grid>
        <Grid item xs={12} sm>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            className={styles.button}
            onClick={onAccept}
          >
            Alle akzeptieren
          </Button>
        </Grid>
      </Grid>
    </DialogStyled>
  );
}
