import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import React from "react";

const AccordionCustom = styled((props) => (
  <Accordion elevation={0} {...props} />
))(({ theme }) => ({
  backgroundColor: "#fbfbfb",
  border: `1px solid ${theme.palette.divider}`,
  "&:first-of-type": {
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
    "& .MuiButtonBase-root.MuiAccordionSummary-root": {
      borderTopLeftRadius: "6px",
      borderTopRightRadius: "6px",
    },
  },
  "&:last-of-type": {
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px",
  },
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  "&.MuiPaper-root": {
    margin: 0,
  },
}));

const AccordionSummaryStyled = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "1.5rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#f4f4f4",
  },
  "&.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
    backgroundColor: theme.palette.secondary.main,
    "& .MuiTypography-root": {
      color: theme.palette.common.white,
    },
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(90deg)",
    color: theme.palette.secondary.main,
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(270deg)",
    color: theme.palette.common.white,
  },
  "& .MuiAccordionSummary-content": {
    margin: "0",
    padding: "1rem .5rem",
    [theme.breakpoints.up("sm")]: {
      padding: "2rem 1rem",
    },
    "& .MuiTypography-root": {
      textTransform: "uppercase",
      fontSize: "1rem",
      wordBreak: "break-word",
      hyphens: "auto",
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.375rem",
      },
    },
  },
}));

const AccordionDetailsStyled = styled((props) => (
  <AccordionDetails {...props} />
))(() => ({
  backgroundColor: "#f4f4f4",
  padding: "1.5rem 2rem",
}));

export const AccordionStyled = ({ question, toggle, children, id }) => {
  return (
    <AccordionCustom>
      <AccordionSummaryStyled
        aria-controls={id ? `panel${id}-content` : "panel1d-content"}
        id={id ? `panel${id}-header` : "panel1d-header"}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs>
            <Typography>{question}</Typography>
          </Grid>
          {toggle}
        </Grid>
      </AccordionSummaryStyled>
      <AccordionDetailsStyled>{children}</AccordionDetailsStyled>
    </AccordionCustom>
  );
};
