import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import TabMenuMobile from "@/components/tabs/TabMenuMobile";

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const tabswrapper = {
  boxShadow: "inset 0 -1px #000",
  mb: 5,
  display: {xs: "none", lg: "block"},
};

const TabsCustom = styled((props) => (
    <Tabs elevation={0} {...props} />
))(({theme}) => ({
  overflow: "visible",
  "& .MuiTabs-scroller": {
    overflow: "visible !important",
  },
  "& .MuiButtonBase-root": {
    fontSize: "1.375rem",
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& button.MuiButtonBase-root": {
    color: theme.palette.secondary.main,
    borderLeft: "1px transparent",
    borderRight: "1px transparent",
    borderTop: "1px transparent",
    borderBottom: "1px",
    borderStyle: "solid",
    borderBottomColor: theme.palette.common.black,
  },
  "& button.Mui-selected": {
    color: theme.palette.common.black,
    backgroundColor: "#fbfbfb",
    border: "1px",
    borderColor: theme.palette.common.black,
    borderBottomColor: "transparent",
    borderStyle: "solid",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
}));

const TabsStyled = ({headers, label, onTabChanged, children}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (onTabChanged && typeof onTabChanged === "function") {
      onTabChanged(newValue);
    }
  };

  return (
    <>
        <TabMenuMobile headers={headers} label={headers[value]} onTabChanged={(index) => {handleChange(undefined, index)}} />
        <Grid container>
          <Grid item xs={12}>
            <Box sx={tabswrapper}>
              <TabsCustom
                value={value}
                onChange={handleChange}
                aria-label="Tabs"
              >
                {headers.map((header, idx) => {
                  return (
                      <Tab label={header} key={idx} {...a11yProps(idx)} />
                  );
                })}
              </TabsCustom>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
    </>
  );
};

export default TabsStyled;
