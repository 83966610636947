import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

export const Toggle = ({name, label, value = false, color, defaultChecked = false, disabled = false, showToggle = false, onChange}) => {
    const handleChanged = (event) => {
        if (onChange && typeof onChange === "function") {
            onChange(event, event.target.checked);
        }
    };

    const clickSummary = (event) => {
        event.stopPropagation();
    };

    const handleClick = (event) => {
        if (showToggle && clickSummary && typeof clickSummary === "function") {
            clickSummary(event);
        }
    }

    if (label) {
        return (
            <>
                <FormControlLabel
                    control={
                        <Switch name={name} disabled={disabled} checked={value} defaultChecked={value !== null ? defaultChecked : null} onChange={handleChanged} onClick={handleClick} color={color}/>
                    }
                    label={label}
                />
            </>
        );
    }
    return (
        <>
            <Switch
                name={name}
                onChange={handleChanged}
                color={color}
                checked={value}
                defaultChecked={value !== null ? defaultChecked : null}
                disabled={disabled}
                onClick={handleClick}
            />
        </>
    );
};

Switch.propTypes = {
    label: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary']),
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func
};

Switch.defaultProps = {
    value: true,
    onChange: undefined,
    color: 'primary'
}
