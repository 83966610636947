import theme from "@/styles/theme";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Divider, SvgIcon } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/system";
import * as React from "react";
import { ChevronDownIcon } from "../icons";
import {useState} from "react";

export default function TabMenuMobile({headers, label, onTabChanged}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTabSelected = (index, name) => {
      if (onTabChanged) {
          onTabChanged(index);
      }
      handleClose();
  }

  return (
    <Box sx={{display: {xs: "block", lg: "none"}}}>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        sx={{display: "flex", justifyContent: "space-between"}}
      >
          {label}
      </Button>
      <Divider sx={{margin: ".5rem 0"}}>
        <SvgIcon
          sx={{
            fontSize: ".75rem",
            transform: "translateY(.15rem)",
            color: theme.palette.secondary.main,
          }}
        >
          <ChevronDownIcon />
        </SvgIcon>
      </Divider>
      <Menu
        disablePortal
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {width: anchorEl && anchorEl.offsetWidth},
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
          {headers.map((header, idx) => {
              return (
                  <MenuItem key={idx} onClick={() => { handleTabSelected(idx, header) }}>{header}</MenuItem>
              );
          })}
      </Menu>
    </Box>
  );
}
